.c-user-communities-sidebar-view__community-list {
  border-bottom: var(--g-border-primary);
}

.c-user-communities-sidebar-view__community-item {
  align-items: center;
  background-color: var(--g-color-white);
  border: var(--g-border-primary);
  border-bottom: none;
  display: flex;
  margin: 0;
  justify-content: space-between;
  padding: var(--g-spacing-x-small);
  width: 100%;
}

.c-user-communities-sidebar-view__add-section {
  align-items: center;
  display: flex;
  gap: var(--g-spacing-x-small);
  justify-content: flex-end;
}

.c-user-communities-sidebar-view__add-section .c-button--danger--tertiary {
  padding: var(--g-spacing-4x-small) var(--g-spacing);
  font-size: var(--g-font-size-small);
}

.c-user-communities-sidebar-view__add-icon,
.c-user-communities-sidebar-view__add {
  height: var(--g-spacing-2x-large);
  width: var(--g-spacing-2x-large);
}

.c-user-communities-sidebar-view__bin-icon {
  height: var(--g-spacing-x-large);
  width: var(--g-spacing-x-large);
  stroke: var(--g-color-black);
}

.c-user-communities-sidebar-view__bin-icon:hover,
.c-user-communities-sidebar-view__bin-icon:focus {
  stroke: var(--g-color-red-400);
}

.c-user-communities-sidebar-view__add,
.c-user-communities-sidebar-view__bin {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.c-user-communities-sidebar-view__bin {
  display: flex;
  align-items: center;
}
