/* stylelint-disable selector-class-pattern */
.c-content-builder-toolbar {
  align-items: center;
  background-color: var(--g-color-white);
  border-bottom: 2px solid var(--g-color-grey-100);
  display: flex;
  width: 100%;
  height: 3.25rem;
  justify-content: flex-start;
  padding:  var(--g-spacing-2x-small)  var(--g-spacing) var(--g-spacing-2x-small) var(--g-spacing) ;
}

.c-content-builder-toolbar__dashboard-link {
  align-items: center;
  display: flex;
  gap: var(--g-spacing-small);
  width: var(--c-left-sidebar-max-width);
}

.c-content-builder-toolbar__dashboard-link:hover {
  cursor: pointer;
}

.c-content-builder-toolbar__icon-container {
  align-items: center;
  border: 1px solid var(--g-color-grey-100);
  border-radius: var(--g-border-radius-full);
  display: flex;
  height: var(--g-spacing-x-large);
  justify-content: center;
  padding: var(--g-spacing-2x-small);
  width: var(--g-spacing-x-large);
}

.c-content-builder-toolbar__text {
  color: var(--g-color-black);
  font-family: var(--g-font-primary);
  font-weight: var(--g-font-weight-light);
}

.c-content-builder-toolbar__icon {
  transform: rotate(180deg);
}


.c-content-builder-toolbar__items {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.c-content-builder-toolbar__item {
  align-items: center;
  display: flex;
  gap: var(--g-spacing-x-small);
}